.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }

.modal {
    position: fixed;
    top: 10vh;
    left: 5%;
    width: 90%;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
    overflow-x:auto;
    max-width:1000px;
}

.modalLoading {
    position: fixed;
    top: 38%;
    width: 100%;
    background-color: transparent;
    padding: 0;
    border-radius: 0px;
    z-index: 30;
    animation: Modal_slide-down__oC10b 300ms ease-out forwards;
    overflow-x: auto;
    max-width: 1000px;
    text-align: center;
    left: 24%;
}
  
  @media (min-width: 768px) {
    .modal {
        width: 70rem;
        left: calc(40% - 20rem);
        overflow: auto
    }
  }
  
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .content{
      overflow:auto;
  }