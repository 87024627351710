/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
/*
.container{
    min-width:95% !important;
}*/

 .MuiDataGrid-cellContent {
    font-size: 0.8rem !important;
}
.MuiDataGrid-cell {
    font-size: 0.8rem !important;
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
    padding: 0 5px !important;
}

.MuiInputBase-input {
    font-size: 0.8rem !important;
}

.MuiDataGrid-columnHeaders {
    background-color: #eee;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
}

.MuiDataGrid-cell .SelectedCell {
    background-color: #E0FFFF;
}
 