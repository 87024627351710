body {
}

.input
{
    margin: 2px;
    padding: 2px 2px 2px 2px;
}

.search_filter
{
    margin-bottom:10px;
    margin-top:20px;
    padding:10px;
    border: 1px solid #808080;
    border-radius:5px 5px;
}

.search_filter div
{
    margin: 10px 10px;
    padding:10px;
    display:inline;

}

.form-group{
    padding: 5px;
}

.cursor{
    cursor:pointer;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
    background-color: #eee;
}

.tooltip{
    max-width:500px;
    overflow:auto;
    z-index:9999;
}

.ql-editor {
    min-height: 200px;
    max-height:300px !important;
}

.iframe{
    width:500px !important;
}

.modal-content_draft_post{
    width:100%;
    
}

.notesDiv{
    padding-bottom:5px;
    font-size:0.9rem;
    border-bottom:1px solid #808080;
}

